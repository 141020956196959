import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getApiErrorMessage } from '@/lib/utils/Utils';

import store from '@/admin/store';
import {
    createMail,
    createMailAll,
    getMail,
    removeMail,
} from '@/admin/api/mailing';
import { getUsersList } from '@/admin/api/users';
import SiteModule from '@/admin/store/site';

export const MODULE_NAME = 'mailingEdit';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class MailingEdit extends EntityBaseModule {
    model: any;
    requestSort = ''

    constructor(module: MailingEdit) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_SUBJECT(value: string) {
        this.model.subject.value = value;
    }

    @Mutation
    SET_MESSAGE(value: string) {
        this.model.message.value = value;
    }

    @Mutation
    SET_IS_ALL(bool: boolean) {
        this.model.isAll.list[0].checked = bool;
    }

    @Mutation
    SET_TO_EMAILS(value: string[]) {
        this.model.toEmails.selected = value;
    }

    @Action({ rawError: true })
    async initCardById(categoryId: number | string) {
        const result = await getMail(categoryId);

        this.setInfo(result);
    }

    @Action({ rawError: true })
    resetError(field: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', field);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_SUBJECT', data.subject);
        this.context.commit('SET_MESSAGE', data.message);
        this.context.commit('SET_IS_ALL', false);
        this.context.commit('SET_TO_EMAILS', data.toEmails);
        this.resetError('subject');
        this.resetError('message');
        this.resetError('toEmails');
    }

    @Action({ rawError: true })
    updateIsAll(bool: boolean) {
        this.context.commit('SET_IS_ALL', bool);
    }

    @Action({ rawError: true })
    async searchToEmails(search: string) {
        this.model.toEmails.value = search;

        const table = await getUsersList(1, this.requestSort, `&filters[0][id]=email&filters[0][value]=${search}`, 50);

        const hintList = table.rows.map((item: any) => {
            return {
                id: item.id,
                value: `${item.email}`,
            };
        });

        this.model.toEmails.hintsList = hintList;
    }

    @Action({ rawError: true })
    async remove(id: string) {
        try {
            await removeMail(id);

            ResponseHandlerModule.showNotify({ message: 'Успешно', type: 'ok' });
        } catch(error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    updateSelectedToEmails(selected: { id: number; value: string }) {
        const selectedList = this.model.toEmails.selected;
        const find = selectedList.findIndex((item: { id: number }) => {
            return item.id === selected.id;
        });

        if (find === -1) {
            this.model.toEmails.selected.push(selected);

            return;
        }

        this.model.toEmails.selected.splice(find, 1);
    }

    @Action({ rawError: true })
    prepareRequestData() {
        const data: any = {
            subject: this.model.subject.value,
            message: this.model.message.value,
        };

        if (!this.model.isAll.list[0].checked) {
            const result = this.model.toEmails.selected.map((item: { value: string }) => {
                return item.value;
            });

            data.toEmails = JSON.stringify(result);
        }

        return data;
    }

    @Action({ rawError: true })
    validate() {
        const errors = [];

        if (!this.model.subject.value?.length) {
            errors.push('subject');
        }

        if (!this.model.message.value?.length) {
            errors.push('message');
        }

        if (!this.model.toEmails.selected?.length && !this.model.isAll.list[0].checked) {
            errors.push('toEmails');
        }

        if (!errors.length) {
            return true;
        }

        errors.forEach((item: string) => {
            this.setValidateState({ index: item, field: this.model[item] });
        });

        return false;
    }

    @Action({ rawError: true })
    async create() {
        const valid = await this.validate();

        if (!valid) {
            return false;
        }

        try {
            if (this.model.isAll.list[0].checked) {
                await createMailAll(await this.prepareRequestData());
            } else {
                await createMail(await this.prepareRequestData());
            }

            ResponseHandlerModule.showNotify({ message: 'Успешно отправлено', type: 'ok' });

            return true;
        } catch(error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });

            return false;
        }
    }

    @Action({ rawError: true })
    async repeat() {
        const valid = await this.validate();

        if (!valid) {
            return false;
        }

        const data: {
            subject: string;
            message: string;
            toEmails?: string;
        } = {
            subject: this.model.subject.value,
            message: this.model.message.value,
        };

        if (this.model.toEmails.selected[0] != 'to_everyone') {
            data.toEmails = JSON.stringify(this.model.toEmails.selected);
        }


        try {
            if (this.model.toEmails.selected[0] === 'to_everyone') {
                await createMailAll(data);
            } else {
                await createMail(data);
            }

            ResponseHandlerModule.showNotify({ message: 'Успешно отправлено', type: 'ok' });

            return true;
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });

            return false;
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_SUBJECT', null);
        this.context.commit('SET_MESSAGE', '');
        this.context.commit('SET_IS_ALL', false);
        this.context.commit('SET_TO_EMAILS', []);
        this.resetError('subject');
        this.resetError('message');
        this.resetError('toEmails');

        SiteModule.SET_IS_BLOCK(false);
    }
}

export default getModule(MailingEdit);
