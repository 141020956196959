
import { InputSuggestionMultipleList } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import { MultiSelectItem } from '@/lib/formFactory/multiSelect.interface';
import { Mixins, Component, Prop } from 'vue-property-decorator';

import InputSuggestionsMixin from './InputSuggestionsMixin';

@Component
export default class InputSuggestionsComponent extends Mixins(InputSuggestionsMixin) {
    @Prop({ required: true }) readonly data!: any;
    @Prop({ type: Boolean, default: false }) isObject!: boolean;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;

    get joinedSelectedItems(): string {
        if (this.isObject) {
            const result = this.data.selected.map((item: { value: string }) => {
                return item.value;
            });

            return result.join(', ');
        }

        return (this.data.hintsList as InputSuggestionMultipleList)
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(', ');
    }

    get labelIsActive(): boolean {
        return this.isShowHints || this.data.value || this.data.value === 0 || this.joinedSelectedItems.length;
    }

    updateSearch(e: Event): void {
        const value = (e.target as HTMLInputElement).value;
        this.isShowHints = true;
        this.$emit('input', value);
    }

    selectValue(item: MultiSelectItem): void {
        this.$emit('click', item);
    }

    isSelect(id: number): boolean {
        if (!this.isObject) {
            return false;
        }

        const result = this.data.selected.find((item: { id: number }) => {
            return id === item.id;
        });

        return Boolean(result);
    }
}
