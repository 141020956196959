import InputEntity from '@/lib/formFactory/inputEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import InputSuggestionMultipleEntity from '@/lib/formFactory/inputSuggestionsMultipleEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const subjectEntity = new InputEntity('subject');
        subjectEntity.setTitle('Тема');
        subjectEntity.setRequired(true);

        const messageEntity = new InputEntity('message');
        messageEntity.setTitle('Сообщение');
        messageEntity.setRequired(true);
        messageEntity.setSize(1000);

        const toEmails = new InputSuggestionMultipleEntity('toEmails');
        toEmails.setTitle('Кому');
        toEmails.setRequired(true);

        const isAll = new CheckboxEntity('isAll');
        isAll.setList({
            0: {
                id: 1,
                name: 'Выслать всем',
                checked: false,
            },
        });

        this.model = {
            subject: subjectEntity.entity[subjectEntity.entityName],
            message: messageEntity.entity[messageEntity.entityName],
            toEmails: toEmails.entity[toEmails.entityName],
            isAll: isAll.entity[isAll.entityName],
        };
    }
}
