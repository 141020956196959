
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import MailingEditModule from '@/admin/store/mailing/entity';
import InputBlock from '@/components/form/InputMain.vue';
import Editor from '@/components/modules/Editor.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import InputSuggestions from '@/components/input/InputSuggestionsMultiple.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';

@Component({
    components: {
        Layout,
        TitleReturn,
        InputBlock,
        Editor,
        CheckboxCustom,
        InputSuggestions,
        BtnDefault,
        BtnSubmit,
    },
})
export default class MailingEdit extends Vue {
    editorCounter = 0;

    get model() {
        return MailingEditModule.model;
    }

    get typeAction(): string {
        return this.$route.params.id === 'create' ? 'create' : 'edit';
    }

    get toEmailsSend(): string {
        if (this.typeAction === 'edit') {
            if (this.model.toEmails.selected[0] === 'to_everyone') {
                return 'Всем';
            }

            return this.model.toEmails.selected.join('\r\n');
        }

        return '';
    }

    updateSubject(value: string): void {
        MailingEditModule.updateModelFieldValue({ field: 'subject', value });

        MailingEditModule.resetError('subject');
    }

    updateMessage(value: string): void {
        MailingEditModule.updateModelFieldValue({ field: 'message', value });

        MailingEditModule.resetError('message');
    }

    updateIsAll(params: { name: string; id: number; bool: boolean }): void {
        MailingEditModule.updateIsAll(params.bool);
    }

    updateToEmails(search: string): void {
        MailingEditModule.searchToEmails(search);

        MailingEditModule.resetError('toEmails');
    }

    updateSelectedToEmails(selected: { id: number; value: string }): void {
        MailingEditModule.updateSelectedToEmails(selected);
    }

    async send(): Promise<void> {
        const result = await MailingEditModule.create();

        if (!result) {
            return;
        }

        this.returnBefore();
    }

    async repeat(): Promise<void> {
        const result = await MailingEditModule.repeat();

        if (!result) {
            return;
        }

        this.returnBefore();
    }

    closeToEmails() {
        MailingEditModule.searchToEmails('');
    }

    async remove(): Promise<void> {
        await MailingEditModule.remove(this.$route.params.id);

        this.returnBefore();
    }

    returnBefore(): void {
        this.$router.push({ name: 'mailing' });
    }

    async mounted(): Promise<void> {
        if (this.typeAction === 'edit') {
            await MailingEditModule.initCardById(this.$route.params.id);

            this.editorCounter++;

            return;
        }

        MailingEditModule.reset();

        this.editorCounter++;
    }
}
